import * as React from "react"
import { lazy } from "@uploadcare/client-suspense"
import { Suspense } from "@uploadcare/client-suspense"
import Loader from "@components/Loader"
import Breadcrumbs from "../components/Breadcrumbs"
import Layout from "../components/layout"
import PostCategorys from "../components/posts/PostCategorys"
import PostNavigation from "../components/PostNavigation"
import RelatedPosts from "../components/RelatedPosts"
// import SidebarTrial from "../components/banners/SidebarTrial"
// import WritersInHero from "../components/banners/WritersInHero"
import PopupDiscount from "../components/popup/PopupDiscount"
import PopupDeadline from "../components/popup/PopupDeadline"
// import Loader from "../components/Loader"
// import Sprite from "../images/sprites/sprite.svg"
// import Statistics from "../components/mainpage/Statistics"
import BottomBanner from "../components/BottomBanner"
import Sharing from "../components/Sharing"
import SampleCarousel from "../components/SampleCarousel"
import Seo from "../components/Seo.js"
// import ContactUs from "@components/ContactUs"
import About from "../components/mainpage/About"

import CalculatorSimple from "@components/CalculatorSimple"


import { StructuredDataHelper } from "@helpers/structuredDataHelper.js"
import { BaseUrlLink} from "../helpers/baseUrl";

import CountdownСounterBanner from "./../components/banners/CountdownСounterBanner"
// import CountdownСounterBannerSecond from "./../components/banners/CountdownСounterBannerSecond"

const Writers = lazy(() => import('@components/mainpage/Writers'));
//Lazy components
const TheVideo = lazy(() => import('../components/mainpage/TheVideo'));

const Landing = ({pageContext}) => {
    const structuredData = new StructuredDataHelper(pageContext)
    const post = pageContext
    // const [hideVideo, setHideVideo] = useState(true)
    
    // breadcrumbs
    const steps = [
        {
            name: 'Essay Writing services',
            path: '/writing-services'
        },
        {
            name: post.post_slug.replace(/-/g, ' '),
            path: post.url
        }
    ]

    // seo data
    const product = {
        context:'https://schema.org/',
        type:'Product',
        name: post.post_keyword_singular,
        images: ['https://www.customessaymeister.com/img/og_image.png'],
        brand:{
            '@type':'Thing',
            name: 'CustomEssayMeister'
        },
        description:post.post_meta_description,
        reviews: [
            {
                '@type': 'Review',
                name: 'Review',
                reviewRating: {
                    '@type': 'Rating',
                    name: 'Rating',
                    worstRating: '1',
                    bestRating: '10',
                    ratingValue: '9'
                },
                author: {
                    '@type': 'Person',
                    name: 'Anonymous Client'
                }
            }
        ],
        aggregateRating: {
            '@type': 'AggregateRating',
            name: 'AggregateRating',
            ratingValue: '9.25',
            worstRating: '1',
            bestRating: '10',
            reviewCount: '65074'
        },
        offers: {
            '@type': 'AggregateOffer',
            name: 'AggregateOffer',
            lowPrice: '14.95',
            highPrice: '34.95',
            priceCurrency: 'USD',
            offerCount: '1'
        }
    }
    const pageData = {
        title: post.post_meta_title,
        description: `⭐ ${post.post_meta_description}`,
        keywords: post.post_keyword_singular + `,` + post.post_keyword_plural,
        robots: 'index, follow',
        micromarking: structuredData.getProductJsonLd(product)
    }

    const writersSliderSetting = {
        responsiveViewDesc: 4,
        responsiveViewTablet: 3,
        responsiveViewTablet2: 2,
        responsiveViewMobile: 1,
        animationDuration: 400,
        autoPlayInterval: 400,
        animationType: 'slide',
        autoPlay: false,
        deskTop: true
    }

    return (
        <>    
            <Seo {...pageData} />
            <Layout>
                <div className="landing-intro page-intro">
                    <div className="landing-intro__wrapper wrapper">
                        <div className="landing-intro__info">
                            <Breadcrumbs steps={steps}/>
                            <h1 className="page-intro__title landing-intro__title">{post.post_title_primary}</h1>
                            <PostCategorys info={post}/>
                            <ul className="intro-list">
                                <li className="intro-description">We can write your {post.post_keyword_singular.toLowerCase()} per YOUR INSTRUCTIONS</li>
                                <li className="intro-description">We deliver your {post.post_keyword_singular.toLowerCase()} ON TIME</li>
                                <li className="intro-description">We pledge 100% SATISFACTION for your {post.post_keyword_singular.toLowerCase()}</li>
                            </ul>
                            <div className="landing-intro__actions">
                                <a href={`${BaseUrlLink}/register`} className="button button-primary">{post.post_button_keyword || `Order now`}</a>
                                {/* <div aria-hidden className="button button-video" onClick={() => setHideVideo(false)}>
                                    <span className="button-video__icon"></span>
                                </div> */}
                            </div>
                        </div>
                        <div className="landing-intro__banner">
                        <CalculatorSimple />
                        {/*    <WritersInHero />*/}
                        </div>
                        {/* {!hideVideo && 
                        <div className="video">
                            <div className="video-modal">
                                <p className="video-modal__title">
                                    Video introduction
                                    <span aria-hidden onClick={() => setHideVideo(true)}>
                                        <img src={Cross} alt="close" />
                                    </span>
                                </p>
                                <div className="iframe_container">
                                    <iframe
                                        src="https://www.youtube.com/embed/8MfY7csqKgo"
                                        title="YouTube video player"
                                    />
                                </div>
                            </div>
                        </div>
                        } */}
                    </div>
                    {/* <Statistics sprite={Sprite} keyword_singular={post.post_keyword_singular} keyword_plural={post.post_keyword_plural}/> */}
                </div>
                    <Suspense fallback={<Loader/>}>
                        <TheVideo image='curveOval'/>
                    </Suspense>
                {/* <Calculator /> */}
                <Suspense fallback={<Loader/>}>
                    <Writers writersSliderSetting={writersSliderSetting} >
                        {/* <About/> */}
                    </Writers>
                </Suspense>
                <br/>
                {/* <CountdownСounterBannerSecond /> */}
                <div className="post__content landing-post__content wrapper">
                    <div className="post__content-wrapper">
                        {/* <ContactUs />
                        <br /> */}
                        <div className="post-navigation__mobile">
                            {post.table_of_contents.length > 0 && <PostNavigation table={post.table_of_contents}/>}
                        </div>
                        <div
                            className="content-block"
                            dangerouslySetInnerHTML={{ __html: post.processed_content }}
                        />
                        {/* <Sharing
                            url={`https://www.customessaymeister.com/${post.post_slug}.html`}
                            title={post.post_meta_title}
                            description={post.post_meta_description}
                            image="https://www.customessaymeister.com/img/og_image.png"
                            tags={["customessaymeister", "writing"]}
                        /> */}
                    </div>
                    <div className="post__content-banners">
                        <div className="banners-wrapper">
                            {post.table_of_contents.length > 0 && <PostNavigation table={post.table_of_contents}/>}
                            {/* <SidebarTrial /> */}
                            <RelatedPosts 
                                posts={post.related}
                                slug={post.post_slug}
                                path="landing"
                                columnTitle={`more in ${post.category_group_title} / ${post.category_title}`} 
                            />
                        </div>
                    </div>
                </div>
                
                {/* <CountdownСounterBanner /> */}
                <br/>
                <br/>
                <SampleCarousel category={post.category_title} keyword={post.post_keyword_singular}/>
                <br/>
                <br/>
                <BottomBanner keyword={post.post_keyword_singular} />
                <PopupDiscount timer={15000}/>
                <PopupDeadline timer={5000}/>
            </Layout>
        </> 
    )
}
export default Landing
