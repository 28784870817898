import React, { useState, useRef } from "react"
import Arrow from "@components/ImagesComponents/Arrow"
const PostNavigation = (props) => {
    const [listHeight, setListHeight] = useState();
    const [showList, setShowList] = useState();
    const maxListHeight = 500
    const list = useRef()
    const navContainer = useRef()

    const openList = () => {
        if (!showList) {
            setListHeight(list.current.scrollHeight < maxListHeight ? list.current.scrollHeight : maxListHeight )
        }
        setShowList(true)
    }
    return (
        <div className="post-navigation__container">
            <div
                aria-hidden
                className={`post-navigation`}
                ref={navContainer}
            >
                <div className="post-navigation__wrapper"
                    aria-hidden
                    onMouseOver={() => openList()}
                    onFocus={() => openList()}
                    onMouseLeave={() => setShowList(false)}
                >
                    <div className="post-navigation__header">
                        TABLE OF CONTENTS
                        <Arrow imageStyle={showList ? {rotate: '-90deg'} : {rotate: '90deg'}}/>
                    </div>
                    <div className={showList ? 'list-padding' : ''}>
                        <ul 
                            className={`post-navigation__list ${listHeight === maxListHeight ? 'list-scroll' : ''}`}
                            style={showList ? {maxHeight: listHeight + 'px'} : {maxHeight: '0'}}
                            ref={list}
                        >
                            {props.table.map((title, index) => {
                                return(
                                    <li
                                        aria-hidden
                                        className="post-navigation__title"
                                        key={index}
                                        onClick={() => scrollToTitle(title.slug)}
                                    >
                                        {title.title}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
    
    function scrollToTitle(name) {
        window.history.pushState({}, "", `#${name}`)
        let element = null
        const yOffset = -180
        let yAxis = null
        const collection = document.querySelectorAll('.content-block a')
        collection.forEach((e) => {
            if (e.name === name) {
                element = e
                yAxis = element.getBoundingClientRect().top + window.pageYOffset + yOffset
                window.scrollTo({ top: yAxis , behavior: 'smooth' })
            }
        })        
    }
}

export default PostNavigation