import React from 'react';

function Arrow(props) {
    return (
        <svg style={props.imageStyle} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={props.pathStyle} d="M1 1L6 6L1 11" stroke="#FF690C" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
}

export default Arrow;