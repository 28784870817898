import React, {useState} from "react"
import { samples } from '../helpers/samples'
import AliceCarousel from 'react-alice-carousel';
import SliderPager from "./SliderPager";

import FileCheck from "@components/ImagesComponents/FileCheck"

const SampleCarousel = (props) => {
    const [counter, setCounter] = useState(0)
    let list = samples
    if (props?.category) {
        list = samples.filter(item => {
            return item.type === props.category
        })
    }
    const responsive = {
        0: { items: 1 },
        660: { items: 1 },
        768: { items: 2 },
        992: {items: 2},
        1124: { items: 3 }
    }

    const items = list.map((item, index) => {
        return (
            <div key={index} className="carousel_item samples">
                <div style={{marginBottom: '10px'}}>
                    <FileCheck />
                </div>
                <p className="samples_title">
                    { item.type }
                </p>
                <div className="samples_body">
                    <div style={{width: '100%', marginBottom: '25px'}}>
                        <p className="samples_body_title">
                            Title of Piece:
                        </p>
                        <p className="samples_body_desc">
                            { item.title }
                        </p>
                    </div>
                    <div style={{width: '100%', marginBottom: '25px'}}>
                        <p className="samples_body_title">
                            Discipline:
                        </p>
                        <p className="samples_body_desc">
                            { item.discipline }
                        </p>
                    </div>
                    <div className="samples_body_col">
                        <p className="samples_body_title">
                            Academic level:
                        </p>
                        <p className="samples_body_desc">
                            { item.level.text }
                        </p>
                    </div>
                    <div className="samples_body_col">
                        <p className="samples_body_title">
                            Pages:
                        </p>
                        <p className="samples_body_desc">
                            { item.pages }
                        </p>
                    </div>
                    <div className="samples_body_col">
                        <p className="samples_body_title">
                            Paper format:
                        </p>
                        <p className="samples_body_desc">
                            { item.format }
                        </p>
                    </div>
                    <div className="samples_body_col">
                        <p className="samples_body_title">
                            Sources:
                        </p>
                        <p className="samples_body_desc">
                            { item.sources }
                        </p>
                    </div>
                    <div className="samples_body_col samples_footer bottom-btn mb-0 bottom-col">
                        <a href={`/sample/${item.slug}`} className="button button-primary" target="_blank" rel="noreferrer noopener" >
                            <span className="eye_icon"/>
                            View
                        </a>
                    </div>
                    <div className="samples_body_col samples_footer bottom-btn mb-0 bottom-col">
                        <a href={`/pdf/${item.fileName}.pdf`} download className="button button-border__main">
                            <span className="download_icon"/>
                            Download
                        </a>
                    </div>
                </div>
            </div>
        )
    })
    return (
        <div>
            {list.length > 0 &&
                <div className={`carousel-wrapper ${list.length <= 2 ? 'easy-carousel' : ''}`}>
                    <p className="samples-title">
                        Samples of {props.keyword || 'our papers'}
                    </p>
                    <p className="samples-descr">
                        Below are the samples of custom written essays and term papers written by professional academic writers 
                        in different academic fields of study and on
                        various complexity levels.
                    </p>
                    <div className="carousel samples-carousel">
                        <div
                            className="slider wrapper"
                        >
                            <AliceCarousel
                                // mouseTracking
                                touchMoveDefaultEvents="false"
                                items={items}
                                onSlideChanged={(e) => setCounter(e.item)}
                                activeIndex={counter}
                                responsive={responsive}
                                // infinite
                                controlsStrategy="alternate"
                            />
                        </div>
                        <SliderPager count={counter + 1} all={items.length} />
                        <a href="https://www.customessaymeister.com/info/samples" className="button button-primary button-carousel">all samples</a>
                    </div>
                </div>
            }
        </div>
    )
}

export default SampleCarousel
