import * as React from "react"

const CalculatorSimple = () => {
    return (
        <div className="calclulator-wrapper">
            <iframe
                data-src="https://calc.customessaymeister.com/simple?hours=164"
                data-calclulator=""
                scrolling="no"
                style={{width: 100 + '%'}}
                className="calculator-iframe-simple lazyload"
                title="calculator"
                loading="lazy"
            ></iframe>
        </div>
    )
}

export default CalculatorSimple
